.why__container {
  display: flex;
  /* width: 70%; */
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 20px;
}

.whyContainer__letter {
  border: 1px solid var(--fc-background);
  border-radius: 4px;
  overflow: hidden;
  margin: 30px;
  transition: transform 100ms ease-in;
  width: 750px;
  cursor: pointer;
  padding: 60px;
}

.whyContainer__letter > p {
  text-align: left;
}

.whyContainer__letter > p > a {
  color: #de3127;
  text-decoration: none;
}

.whyContainer_image {
  max-width: 100%;
  max-height: 100%;
}
