.footerMini {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.footer__top {
  display: flex;
  justify-content: center;
}

.footer__logo {
  margin: 20px 0px;
  display: flex;
  justify-content: center;
}

.footer__text {
  display: flex;
  justify-content: center;
}

.footer__text > p {
  padding-right: 5px;
}

.footer__base {
  display: flex;
  justify-content: center;
  margin-top: 2px;
  font-size: 13px;
  color: gray;
}

.footer__logo > img {
  object-fit: contain;
  width: 100px;
  /* width: 100%; */
}

.header__link > span {
  padding: 0px 5px;
}
