.button__ac {
  text-transform: none !important;
  background-color: var(--fc-color) !important;
  border-radius: 50px !important;
  padding: 5px 20px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 700px !important;
  color: white !important;
  white-space: nowrap;
}
