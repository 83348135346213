.founderContainer {
  display: flex;
}

h1 {
  font-size: 30px;
}

h3 {
  font-weight: normal;
}
.founderContainer__left {
  flex: 0.3;
  max-width: 260px;
}

.founderContainer__right {
  display: flex;
  flex-direction: column;
  padding: 20px 0px 20px 20px;
  max-width: 100%;
}

.founderContainer__right p {
  margin-top: 10px;
  font-size: 13px;
  color: gray;
}

.founderContent__topSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
}

.founderContainer__image > img {
  margin: 20px 0px;
  object-fit: cover;
  width: 250px;
  height: 250px;
  /* width: 100%; */
  border-radius: 50%;
}

.founderContainer__filters > button {
  border-radius: 30px;
  text-transform: inherit;
  margin: 10px 5px 0px 0px;
}

.founderContainer__location {
  margin: 20px 0px;
}

.founderContainer__companies {
  margin: 20px 0px;
}

.founderContainer__companies > div > a {
  text-decoration: none;
  text-transform: none;
}

.founderContainer__companyLogo {
  margin: 20px 0px;
  object-fit: fill;
  width: 120px;
}

.founderContainer__content {
  /* padding: 20px 20px 0px 20px; */
  display: flex;
  flex-wrap: wrap;
}

.founderContainer__profiles {
  display: flex;
  flex-direction: column;
  /* margin: 5px 0px; */
  padding: 10px;
  padding-left: 23px;
}

.founderContent__follow {
  margin-top: 40px !important;
  text-transform: none !important;
  background-color: var(--fc-color) !important;
  border-radius: 40px !important;
  padding: 0px 20px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 700px !important;
  color: white !important;
  height: 50px;
  /* width: 120px; */
}

.founderContainer__avatar {
  object-fit: cover;
}

.founderContainer__profiles > a {
  text-decoration: none;
  color: inherit;
}
