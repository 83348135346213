.founderFeedoption__container {
  border: 1px solid var(--fc-background);
  border-radius: 4px;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 20px;
  transition: transform 100ms ease-in;
  /* width: 32%; */
  height: 300px;
  cursor: pointer;
}

.founderFeedoption__container:hover {
  transform: scale(1.07);
}

.founderFeedOption__resourceTitle {
  height: 20px;
  padding: 10px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}
