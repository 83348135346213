.signupContainer {
  display: flex;
  align-items: center;
  height: 85vh;
}

.signupContainer__left {
  flex: 0.5;
  padding-right: 30px;
  padding-left: 10px;
}

.signupContainer__left > h1 {
  font-size: 24px;
  padding: 40px 0px 20px 0px;
}

.signupContainer__right {
  flex: 0.5;
  padding: 10px;
}

.signupContainer__image {
  width: 100%;
  object-fit: contain;
}

.signupContainer__signup {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.signupContainer__input {
  display: flex;
  /* flex: 0.3; */
  align-items: center;
  background-color: var(--fc-background);
  padding: 10px;
  border-radius: 20px;
  margin-top: 10px;
  border: none;
  margin: 20px 10px 20px 0px;
  width: 260px;
}

.signupContainer__input > input {
  border: none !important;
  background-color: var(--twitter-background);
  outline: none;
}
