* {
  margin: 0;
}

.app__body {
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0px;
}

body {
  font-family: "Poppins", sans-serif !important;
}
