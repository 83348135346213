.feed {
  padding: 20px 20px 20px 20px;
  max-width: 100%;
}

.feed > h1 {
  font-size: 30px;
}

.feed > div > h3 {
  font-weight: normal;
}

.feed > div > p {
  margin-top: 10px;
  font-size: 13px;
  color: gray;
}

.feed__filters > button {
  border-radius: 30px;
  text-transform: inherit;
  margin: 10px 5px 0px 0px;
}

.feed_feedOptions {
  width: 100%;
  padding-top: 7px;
  display: flex;
  flex-wrap: wrap;
  /* overflow: auto;
  height: 700px; */
}

.feedOption__resourceExcerpt {
  padding: 0px 15px;
}
