.sidebarOption {
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}
.sidebarOption:hover {
  background-color: #f5f5f5;
  /* transition: ease-in 100ms; */
}

.sidebarOption > span {
  padding-right: 10px;
}

.sidebar__slackCommunity {
  display: flex;
  align-items: center;
  padding: 20px 0px;
}

.sidebar__slackLogo {
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.sidebar__slackLogo > img {
  width: 25px;
  height: 25px;
}

.sidebar__facebookLogo {
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.sidebar__facebookLogo > img {
  width: 25px;
  height: 25px;
}

.sidebar__facebookCommunity {
  display: flex;
  align-items: center;
}

.sidebar__subscription {
  padding: 40px 0px 0px 0px;
}

.signupContainer__signup > ._button-wrapper > .button__main {
  width: 105px;
  height: 50px !important;
}
