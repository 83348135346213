.sidebar {
  max-width: 260px;
  padding: 0px 20px;
}

.sidebar__input {
  display: flex;
  flex: 0.3;
  align-items: center;
  background-color: var(--fc-background);
  padding: 10px;
  border-radius: 20px;
  margin-top: 10px;
  border: none;
  margin: 20px 0px;
  max-width: 260px;

  /* width: 100%; */
}

.sidebar__input > input {
  width: 100%;
  border: none !important;
  background-color: var(--twitter-background);
}

.sidebar__input > input:focus {
  outline: 0;
}

.sidebar__button {
  text-transform: none;
  text-decoration: none !important;
  color: inherit !important;
}
