.feedoption__container {
  border: 1px solid var(--fc-background);
  border-radius: 4px;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 20px;
  transition: transform 100ms ease-in;
  height: 350px;
  max-width: 488px;
  cursor: pointer;
}

.feedoption__container:hover {
  transform: scale(1.07);
}

.feedOption__image > img {
  object-fit: cover;
  /* min-width: 300px; */
  min-height: 200px;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.feedOption__resourceTitle {
  height: 80px;
  padding: 10px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}

.feedOption__resourceExcerpt {
  /* padding: 10px;
  font-size: 22px;
  font-weight: 700; */
  text-align: center;
}

.feedOption__resourceExcerpt > span {
  padding: 0px 10px;
}

.feedOption__link {
  text-decoration: none !important;
  color: inherit !important;
}
