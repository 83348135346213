.submit__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 20px;
}

.submitContainer__tweet {
  border: 1px solid var(--fc-background);
  border-radius: 4px;
  overflow: hidden;
  margin: 30px;
  transition: transform 100ms ease-in;
  width: 550px;
  height: 250px;
  cursor: pointer;
}

.submitContainer__tweet > h3 {
  padding: 60px;
}

.submitContainer__tweetButton > button {
  background-color: #1da1f2 !important;
  border-radius: 40px;
  padding: 5px 20px;
  text-transform: none;
  color: white;
}

.submitContainer__tweetSource {
  text-decoration: none !important;
}
