.resourceContainer {
  display: flex;
  flex-direction: column;
}

.resourceContainer__top {
  display: flex;
}

.resourceContainer__left {
  /* flex: 0.7; */
  /* width: 65%; */
  padding: 20px 0px 20px 20px;
  margin-right: 20px;

  /* max-width: 260px; */
}

.resourceContainer__right {
  /* flex: 0.3; */
  display: flex;
  flex-direction: column;
  margin-top: 90px;
  margin-left: 20px;
  /* max-width: 1000px; */
}

h1 {
  font-size: 30px;
}

h3 {
  font-weight: normal;
}

.resourceContainer__left p {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 13px;
  color: gray;
}

.resourceContainer__resource {
  object-fit: cover;
}

.resourceContainer__resource > img {
  margin: 20px 0px;
  max-width: 100%;
  /* height: 250px; */
  /* width: 100%; */
  border-radius: 4px;
}

.resourceContainer__image > img {
  margin: 20px 10px 20px 30px;
  object-fit: cover;
  width: 70px;
  height: 70px;
  /* width: 100%; */
  border-radius: 50%;
}

.resourceContainer__filters > button {
  border-radius: 30px;
  text-transform: inherit;
  margin: 10px 5px 0px 0px;
}

.resourceContainer__founderPath {
  text-decoration: none !important;
  color: inherit;
}

.resourceContainer__founder {
  display: flex;
  align-items: center;
  border-radius: 8px;
}

.resourceContainer__founder:hover {
  cursor: pointer;
  background-color: var(--fc-background);
}

.resourceContainer__profiles {
  display: flex;
  flex-direction: column;
  /* margin: 5px 0px; */
  padding: 10px;
  padding-left: 35px;
}

.founderContainer__founderDetails {
  padding: 0px 10px;
}

.founderContainer__nameProfile {
  display: flex;
  align-items: center;
}

.founderContainer__nameProfile > h2 > .MuiSvgIcon-root {
  font-size: 1rem !important;
  color: #110302;
}

.resourceContainer__buttons {
  display: flex;
  align-items: center;
}

.resourceContainer__follow {
  margin-top: 40px !important;
  margin-left: 30px !important;
  text-transform: none !important;
  text-decoration: none !important;
  background-color: var(--fc-color) !important;
  border-radius: 40px !important;
  padding: 0px 20px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 700px !important;
  color: white !important;
  height: 50px;
  overflow: hidden;
  /* width: 120px; */
}

.resourceContainer__share {
  margin-top: 40px !important;
  margin-left: 2px !important;
  text-transform: none !important;
  text-decoration: none !important;
  /* background-color: var(--fc-color) !important; */
  border-radius: 40px !important;
  padding: 0px 20px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 700px !important;
  /* color: white !important; */
  height: 50px;
  /* width: 120px; */
}

.MuiSvgIcon-root {
  color: #1da1f2 !important;
  padding-right: 5px;
}

.founderContainer > a {
  text-decoration: none;
}

.resourceContainer__relatedContent {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 0px 20px;
}
