.header__madebyDrag {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
}

.header__buttonTop {
  text-align: center !important;
  font-size: 13px;
  padding: 2px 10px;
  border-radius: 4px;
  background: var(--fc-background);
  text-transform: none;
  text-decoration: none !important;
  color: inherit !important;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px;
  height: 40px;
  max-width: 1300px;
  color: var(--fc-black);
}

.header__left {
  display: flex;
  padding-left: 20px;
}

.header__right {
  display: flex;
  justify-content: flex-end;
  padding: 0px 20px;
}

.header__logo {
  height: 30px;
}

.button__primary {
  text-transform: none !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 700px !important;
}

.button__main {
  text-transform: none !important;
  background-color: var(--fc-color) !important;
  border-radius: 40px !important;
  padding: 5px 20px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 700px !important;
  color: white !important;
}

.header__link {
  text-decoration: none !important;
  color: inherit !important;
  white-space: nowrap !important;
}
